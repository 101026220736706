
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-navigation-menu {
    &__search-container {
        display: block;
        background: $nu-white;
        padding: $nu-spacer-2;
        @include breakpoint(large) {
            display: none;
        }
    }

    &__search {
        max-width: 100%;
    }

    &__contentful-block {
        padding: $nu-spacer-2;
        background: $nu-white;
        border-bottom: 1px solid $nu-gray--light;
    }

    &__anonymous-links {
        display: block;
        @include breakpoint(large) {
            display: none;
        }
    }

    &__anonymous-links-header {
        padding-left: $nu-spacer-2;
    }
}
