
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-navigation-menu-list {
    background-color: $nu-white;

    &__a,
    &__li {
        outline-offset: -4px;
    }

    &__a {
        width: 100%;
        padding: $nu-spacer-2;
        border-bottom: 1px solid $nu-gray--light;
        color: $nu-black;
    }

    &__li {
        cursor: pointer;
        padding: $nu-spacer-2;
        width: 100%;
        border-bottom: 1px solid $nu-gray--light;

        display: inline-flex;
        border-bottom: 1px solid $nu-gray--light;
        justify-content: space-between;
        align-items: center;
    }

    &__image-module {
        padding: $nu-spacer-2;
        background: $nu-white;
        border-bottom: 1px solid $nu-gray--light;
    }

    &__featured-content {
        aspect-ratio: 2/1;
        background-color: $placeholder-background;
    }

    &__badge {
        margin-left: $nu-spacer-2;
    }

    &__icon {
        margin-left: auto;
    }
}
