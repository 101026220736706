
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-navigation-links-list {
        background: $nu-white;

        &__a {
            width: 100%;
            padding: $nu-spacer-2;
            border-bottom: 1px solid $nu-gray--light;
            color: $nu-black;
        }
    }
