
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-badge {
        width: auto;
        height: $nu-spacer-4;
        display: inline-flex;
        align-items: center;
        padding: 0 $nu-spacer-2;
        border-radius: v-bind(borderRadius);

        &--mini {
            border-radius: 0 4px 0 0;
            height: $nu-spacer-3;
            padding: 0 6px;
        }

        &--white {
            background-color: $nu-white;
        }

        &--light-gray {
            background-color: $nu-gray--light;
        }

        &--light-gray-translucent {
            background-color: rgba($nu-gray--light, 0.8);
        }

        &--sunshine {
            background-color: $nu-sunshine-80;
        }

        &__icon {
            &:has(+ .c-badge__text) {
                margin-right: $nu-spacer-1;
            }
        }

        &__text {
            display: inline-flex;
        }
    }
