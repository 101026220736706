
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-navigation-menu {
        height: 100%;
        overflow: auto;

        &__content-wrapper {
            overflow: hidden;
            position: relative;
            height: 100%;
        }

        &__content-container {
            height: 100%;
            overflow-y: auto;

            &::after {
                display: block;
                content: ' ';
                height: var(--navContentBottomPadding)
            }
        }

        &__fixed-cta-container {
            border-top: 1px solid $nu-gray--light;
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            left: 0;
            right: 0;
            height: 48px;
            width: 100%;
            background-color: $nu-white;
            text-align: center;
        }
    }
